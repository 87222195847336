import React from 'react'
import EnterpriseClinicalLanding from 'enterprise/clinical/components/EnterpriseClinicalLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

export default function EnterpriseDemoClinical() {
  return (
    <EnterpriseClinicalLanding
      heroProps={{
        logos: LogosSvg,
      }}
      partner={enterprise.DEMO_CLINICAL_KEY}
      className="EnterpriseDemoClinical"
      data-test-id="home"
    />
  )
}
